import Header from "../../components/HomeComponents/Header";
import useWindowDimensions from "../../utils/customHooks/useWindowDimensions";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { SessionContext } from "../../components/UserContext";

function Ruleofuse() {
    const { width,height } = useWindowDimensions();
    const navigate = useNavigate();
    const params = useParams();
    const [data, setData] = useState([]);
    const { state } = useContext(SessionContext);
    return (
        <div style={{
            width: width < 800 ? "100%" : "80%",
		height:"100vh",
            display: "flex",
            flexDirection: "column",
        }}>

            <Header showback heading={"Rules of use"} phone heart/>
            <iframe src="https://vouch365.com/rules-of-use/" title="rules of use" height={height}></iframe>

        </div>
    );
}

export default Ruleofuse;
