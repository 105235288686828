import axios from 'axios';

const instance = axios.create({ baseURL: "https://easypaisa.vouch365.mobi/api/" });

// const instance = axios.create({baseURL: "https://v3beta.vouch365.mobi/api/"});

instance.interceptors.request.use(config => {

  document.getElementById("loader").classList.add("show");

  return config;

});

instance.interceptors.response.use(function (response) {

  document.getElementById("loader").classList.remove("show");

  return response;

}, function (error) {

  document.getElementById("loader").classList.remove("show");

  return Promise.reject(error);

});

export default instance
