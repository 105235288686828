import React, { useState, useEffect, useContext } from "react";
import axios from "../../utils/axios";
import Search from "../../images/Search.svg";
import { SessionContext } from "../../components/UserContext";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/HomeComponents/Header";
import { motion } from "framer-motion";
import useWindowDimensions from "../../utils/customHooks/useWindowDimensions";

const Shops = () => {
  const { width } = useWindowDimensions();

  const productList = [
    {
      id: 2,
      title: 'Karachi "𝟮𝟬𝟮𝟮-𝟮𝟬𝟮𝟯"',
      description:
        "Enjoy over 10K+ Buy 1 Get 1 Free offers 𝗣𝗞𝗥 𝟰̶,𝟱̶𝟬̶𝟬̶ NOW: Valid till 31st Dec 2023",
      image: "https://v3beta.vouch365.mobi/storage/products/Karachi.jpg",
      price: 999,
      color: "#FFFFFF",
      year_to_show: 2023,
      product_type: "membership",
      duration: "one_year",
      duration_value: null,
      all_city_access: 0,
      membership_id: 2,
      status: "active",
    },
    {
      id: 12,
      title: 'KLI "𝟮𝟬𝟮𝟮-𝟮𝟬𝟮𝟯" ',
      description:
        "Enjoy over 23K+ Buy 1 Get 1 Free offers 𝗣𝗞𝗥 𝟵̶,̶𝟱̶𝟬̶𝟬̶ NOW: Valid till 31st Dec 2023",
      image: "https://v3beta.vouch365.mobi/storage/products/KLI.jpg",
      price: 2499,
      color: "#FFFFFF",
      year_to_show: 2023,
      product_type: "membership",
      duration: "one_year",
      duration_value: null,
      all_city_access: 0,
      membership_id: 2,
      status: "active",
    },
  ];

  const [searchTerm, setSearchTerm] = useState("");
  const [product, setProducts] = useState([]);
  const { state } = useContext(SessionContext);
  const navigate = useNavigate();

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredProducts = product.filter((product) =>
    product.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    axios
      .post("https://easypaisa.vouch365.mobi/api/get-products-filtered", {
        membership_id: state.user.membership.id,
        city_id: state.selectedCity.id == -1 ? 1 : state.selectedCity.id,
//state.user.city.id,
      })
      .then((response) => {
        setProducts(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <motion.div
      initial={{ x: 300, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 1 }}
      className="page"
      style={{
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        display: "flex",
        height: "100%",
      }}
    >
      <div
        style={{
          width: width < 800 ? "100%" : "80%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Header showback={true} heading={"Shop"} hideOpts={true} />

        <div
          // className="search-container"
          style={{
            backgroundColor: "#E5E5E5",
            alignItems: "center",
            display: "flex",
            width: "80%",
            marginTop: "2%",
            alignSelf: "center",
            borderRadius: "5px",
            justifyContent: "space-between",
          }}
        >
          <input
            style={{
              width: "100%",
              height: "30px",
              borderColor: "#E5E5E5",
              border: "none",
              outline: "none",
              backgroundColor: "#E5E5E5",
            }}
            type="text"
            placeholder="Search by name..."
            value={searchTerm}
            onChange={handleSearch}
          />
          <img
            src={Search}
            alt=""
            style={{
              width: "20.5px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "5%",
            }}
          />
        </div>

        <div
          className="product-cards"
          style={{ width: "90%", alignSelf: "center", marginBottom: "60px" }}
        >
          {filteredProducts.map((product) => (
            <div
              key={product.id}
              style={{
                display: "flex",
                borderBottom: "1px solid  grey",
                marginTop: "10px",
                alignItems: "center",
                justifyContent: "center",
              }}
              className="product-card"
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  textAlign: "center",
                  width: width < 800 ? "100%" : "80%",
                  alignSelf: "center",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                onClick={() =>
                  navigate("/cart", {
                    state: product,
                    selectedItem: product?.image,
                  })
                }
              >
                <img
                  style={{
                    width: "110px",
                    height: "120px",
                    objectFit: "contain",
                    marginLeft: "10px",

                    marginBottom: "10px",
                  }}
                  src={product.image}
                  alt={product.title}
                />
                <div
                  style={{
                    textAlign: "center",
                    marginRight: "20px",
//                    marginTop: "5px",
                  }}
                >
                  <h3>{product.title}</h3>
                  <p>{product.description}</p>
                  <p>Price: {product.price}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <Footer />
      </div>
    </motion.div>
  );
};

export default Shops;
