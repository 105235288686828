import React from "react";

import Home from "../images/home.svg";

import Profile from "../images/profile.svg";

import Settins from "../images/setting.svg";

import Shopping from "../images/shopping.svg";

import { SessionContext } from "./UserContext";

import { useNavigate } from "react-router-dom";

import { useContext, useEffect } from "react";


const Footer = () => {

  const { state } = useContext(SessionContext);

  const loc = window.location.pathname;

  const navigate = useNavigate();

  //console.log(loc == "/");

  return (

    <div

      style={{

        width: "100vw",

        alignSelf: "center",

        position: "fixed",

        bottom: 0,

        left: 0,

        justifyContent: "center",

        alignItems: "center",

        display: "flex",

      }}

    >

      <div

        style={{

          width: "50%",

          backgroundColor: "lightgrey",

          borderTopLeftRadius: 20,

          alignSelf: "center",

          borderTopRightRadius: 20,

          display: "flex",

          justifyContent: "space-around",

          padding: "10px 7px 5px 7px",

        }}

      >

        <img

          src={Home}

          alt=""

          onClick={() => navigate("/HomeScreen")}

          className={loc == "/HomeScreen" ? "highlight" : ""}

          style={{ width: "28px" }}

        />

        {/* <img

          src={Profile}

          alt=""

          style={{ width: "28px" }}

          className={loc.includes("profile") ? "highlight" : ""}

          onClick={() => !state.loggedIn ?? navigate("/login")}

        /> */}

        <img

          src={Settins}

          onClick={() => navigate("/settings")}

          className={loc.includes("settings") ? "highlight" : ""}

          alt=""

          style={{ width: "28px" }}

        />

        <img

          src={Shopping}

          onClick={() => navigate("/shop")}

          alt=""

          style={{ width: "28px" }}

        />

      </div>

    </div>

  );

};


export default Footer;
