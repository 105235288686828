import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "./style.css";
import { Rating } from "react-simple-star-rating";
import useWindowDimensions from "../../utils/customHooks/useWindowDimensions"

const FoodCard = ({ item, imgWidth, imgHeight, cardWidth, rating,
  ScreenName }) => {

  const { width } = useWindowDimensions();
  let styleVendorCard = ScreenName == 'Category' && width > 800 ? {
    width: '17vw'
  } : {}
  let styleImage = width < 800 ? (ScreenName == 'Category' ? {
    height: '120px',objectFit:'cover'
  } : { height: '70%'}) : {}
  return (
    <div className="vendorCard" style={styleVendorCard}>
      <div style={styleImage}>
        <img src={item.header} alt="" className="FoodCardImage" />
      </div>
      <div className="description">
        <p
          style={{
            fontSize: "15px",
            margin: 0,
            fontWeight: "600",
            color: "black",
          }}
        >
          {item.name}
        </p>
        <p style={{ fontSize: "12px", margin: 0, color: "#cdcdcd" }}>
          {item.locality}
        </p>
        {rating ? (
          <div style={{ display: "flex", flexDirection: 'column', }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Rating
                size={18}
                iconsCount={5}
                initialValue={item.rating}
                readonly={true}
              />
              <span style={{ fontSize: "13px", color: "grey", padding: "1px" }}>
                {item.rating}
              </span>
            </div>
            <span style={{ fontSize: "12px", color: "grey" }}>
              ({item.ratings_count} reviews)
            </span>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default FoodCard;
