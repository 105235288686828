import "./index.css";

import Header from "../../components/HomeComponents/Header";

import useWindowDimensions from "../../utils/customHooks/useWindowDimensions";

import Footer from "../../components/Footer";

import arrowRight from "../../images/arrowRight.svg";

import { useNavigate, useParams } from "react-router-dom";

import { useEffect, useState, useContext } from "react";

import { SessionContext } from "../../components/UserContext";

import { motion } from "framer-motion"


function Settings() {

  const { width } = useWindowDimensions();

  const navigate = useNavigate();

  const params = useParams();

  const [data, setData] = useState([]);


  const { state, clearState } = useContext(SessionContext);


  return (

    <motion.div

      initial={{ y: 300, opacity: 0 }}

      animate={{ y: 0, opacity: 1 }}

      transition={{ duration: 1 }}

      className="page" style={{

        alignItems: "center",

        justifyContent: "center",

        flexDirection: "column",

        display: "flex",

      }}>

      <div

        style={{

          width: width < 800 ? "100%" : "80%",

          display: "flex",

          flexDirection: "column",

        }}

      >

        <Header showback heading={"Settings"} phone heart noti profile />


        <div style={{ height: "100%", width: width < 800 ? "100%" : "80%", alignSelf: 'center' }}>

          <div

            style={{

              display: "flex",

              alignItems: "center",

              marginTop: 10,

              flexDirection: "column",

              flexWrap: "wrap",

            }}

          >

            <img src="/images/intro.jpg" alt="splash" style={{ width: "50%" }} />

            <h3>

              {state.user.fname} {state.user.lname}

            </h3>

            <p>{state.user.email}</p>

            <br />

            {/* <button className="login-btn">EDIT ACCOUNT</button> */}

            <br />

            <br />

            {/* <div className="card">

              <b>Family Accounts</b>

              <div className="row">

                <p>My Family</p>

                <img

                  src={arrowRight}

                  style={{ width: "20px" }}

                  onClick={() => null}

                />

              </div>

            </div>

            <br /> */}

            {/* <div className="card">

              <b>My Accounts</b>

              <div className="row" onClick={() => navigate("/activate")}>

                <p>Membership Number</p>

                <img src={arrowRight} style={{ width: "20px" }} />

              </div>

              <div className="row">

                <p>Reset Password</p>

                <img

                  src={arrowRight}

                  style={{ width: "20px" }}

                  onClick={() => null}

                />

              </div>

            </div>

            <br />

            <div className="card">

              <b>Manage Friends</b>

              <div className="row">

                <p>Refer a Friend</p>

                <img

                  src={arrowRight}

                  style={{ width: "20px" }}

                  onClick={() => null}

                />

              </div>

            </div> */}

            <br />

            <div className="card">

              <b>My History</b>

              {/* <div className="row">

                <p>Ping History</p>

                <img

                  src={arrowRight}

                  style={{ width: "20px" }}

                  onClick={() => null}

                />

              </div>

              <div className="row">

                <p>Swap History</p>

                <img

                  src={arrowRight}

                  style={{ width: "20px" }}

                  onClick={() => null}

                />

              </div> */}

              <div className="row" onClick={() => navigate("/redemptions")}>

                <p>Redemption History</p>

                <img src={arrowRight} style={{ width: "20px" }} />

              </div>

            </div>

            <br />

            <div className="card">

              <b>Help & Support</b>

              {/* <a href="https://vouch365.com/contact-us/"> */}

                <div

                  className="row"

                // onClick={() =>

                //   (window.location.href = "https://vouch365.com/contact-us/")

                // }
                onClick={() => navigate("/helplivechat")}

                >

                  <p>Help & Live Chat</p>

                  <img src={arrowRight} style={{ width: "20px" }} />

                </div>

              {/* </a> */}


              {/* <a href="https://vouch365.com/how-it-works/"> */}

                <div

                  className="row"

                // onClick={() =>

                //   (window.location.href = "https://vouch365.com/how-it-works/")

                // }
                onClick={() => navigate("/instructions")}

                >

                  <p>Instructions</p>

                  <img

                    src={arrowRight}

                    style={{ width: "20px" }}

                    onClick={() => null}

                  />

                </div>

              {/* </a> */}

              {/* <a href="https://vouch365.com/rules-of-use/"> */}

                <div

                  className="row"

                // onClick={() =>

                //   (window.location.href = "https://vouch365.com/rules-of-use/")

                // }
                onClick={() => navigate("/ruleofuse")}

                >

                  <p>Rules of Use</p>

                  <img src={arrowRight} style={{ width: "20px" }} />

                </div>

              {/* </a> */}

              {/* <a href="https://vouch365.com/knowledge-base/"> */}

                <div

                  className="row"

                // onClick={() =>

                //   (window.location.href = "https://vouch365.com/knowledge-base/")

                // }
                onClick={() => navigate("/faqs")}

                >

                  <p>FAQs</p>

                  <img src={arrowRight} style={{ width: "20px" }} />

                </div>

              {/* </a> */}

            </div>

            <br />

            <br />

            {/* <button

              className="log"

              onClick={() => {

                clearState();

                navigate("");

              }}

              style={{ marginBottom: "50px" }}

            >

              SIGN OUT

          </button> */}

          </div>

        </div>

        <Footer />

      </div>

    </motion.div>

  );

}


export default Settings;
