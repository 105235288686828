import "../../index.css";
import React from "react";
import Header from "../../components/HomeComponents/Header";
import useWindowDimensions from "../../utils/customHooks/useWindowDimensions";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "../../utils/axios";
import { useContext, useState, useEffect } from "react";
import debounce from "lodash/debounce";
import { SessionContext } from "../../components/UserContext";
import { toast } from "react-toastify";
import HeaderSearch from "../../components/HomeComponents/HeaderSearch";
import FoodCard from "../../components/HomeComponents/FoodCard";
import GoogleMap from "../../components/HomeComponents/GoogleMap";
import { motion } from "framer-motion";

function Search() {
  const { width } = useWindowDimensions();
  const loc = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState(loc.state.search);
  const { state, updateState } = useContext(SessionContext);
  const [vendors, setVendors] = useState([]);
  const [showMap, setShowMap] = useState(false);
  const [leftPadding, setLeftPadding] = useState("20");

  useEffect(() => {
    const debouncedSearch = debounce(() => {
      var cityId = 1;
      if (state.selectedCity.id != -1) {
        cityId = state.selectedCity.id;
      }
      if (searchTerm == "") {
        return;
      }
      var vendorList =
        `searchVendor?city_id=` + cityId + `&searchKey=` + searchTerm;
      const headers = {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${state.token}`,
      };
      axios
        .get(vendorList, {
          headers: headers,
        })
        .then((response) => {
          var dt = response.data.data;
          console.log(dt);
          let vendorCoordinate = dt.map((data) => {
            let vendorObj = {
              id: data.id,
              name: data.name,
              latitude: data.latitude,
              longitude: data.longitude,
            };
            return vendorObj;
          });
          setVendors(vendorCoordinate);
          dt.sort(function (a, b) {
            return b.rating - a.rating || b.ratings_count - a.ratings_count;
          });
          setData(dt);
        })
        .catch((error) => {
          setData([]);
          console.error("There was an error!", error);
        });
    }, 500);
    debouncedSearch();
    return () => {
      debouncedSearch.cancel();
    };
  }, [searchTerm]);

  function searchVendors(e) {
    const term = e.target.value;
    if (term.length == 0) {
      setSearchTerm("");
      return;
    }
    if (term.length < 3) {
      return;
    }
    setSearchTerm(term);
  }
  function viewMap() {
    showMap ? setLeftPadding(20) : setLeftPadding(0);
    setShowMap(!showMap);
  }
  return (
    <motion.div
      initial={{ y: 300, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 1 }}
      className="page"
      style={{
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        display: "flex",
      }}
    >
      <div
        style={{
          width: width < 800 ? "100%" : "80%",
          display: "flex",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        <Header heading="Search" phone showback profile noti heart />

        <div
          className="container"
          style={{
            margin: "20px",
            textAlign: "center",
            marginTop: "10px",
            color: "black",
          }}
        >
          <h5 style={{ margin: 0 }}>LOOKING FOR SOMETHING?</h5>
          <h5 style={{ margin: 0 }}>WE'LL HELP YOU FIND IT</h5>
          <br />
          <HeaderSearch
            sboxVal={loc.state.search}
            dropDown
            HandleSearch={searchVendors}
            viewMap={viewMap}
          />

          {showMap ? (
            <div
              style={{
                position: "fixed",
                left: 0,
                width: "100%",
                height: "100%",
              }}
            >
              <GoogleMap vendors={vendors} />
            </div>
          ) : (
            <div style={{ height: "100%", width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 10,
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                {data.map((item, index) => (
                  <div key={index} style={{ width: "45%", height: "50%" }}>
                    <a
                      onClick={() =>
                        navigate("/offers", {
                          state: { id: item.id },
                        })
                      }
                    >
                      <FoodCard
                        item={item}
                        imgWidth={width}
                        imgHeight={"120px"}
                        rating
                      />
                    </a>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
}

export default Search;
