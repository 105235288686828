import Header from "../../components/HomeComponents/Header";
import useWindowDimensions from "../../utils/customHooks/useWindowDimensions";
import ArrowDown from "../../images/dropdown.svg";
import Back from "../../images/backArrow.svg";
import ArrowUp from "../../images/arrowUp.svg";
import React, { useEffect, useContext, useState } from "react";
import HeaderSearch from "../../components/HomeComponents/HeaderSearch";
import axios from "../../utils/axios";
import { SessionContext } from "../../components/UserContext";

function Filters({
  selectedOffer,
  HandleChange,
  Close,
  selSort,
  sortMethodChange,
}) {
  const { width } = useWindowDimensions();
  const [showAll, setShowAll] = useState(false);
  const [offers, setOffers] = useState([]);
  const { state } = useContext(SessionContext);
  const filterType = ["Sort by", "Offer type", "Food & Drink"];

  useEffect(() => {
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${state.token}`,
    };
    axios
      .get("offers", {
        headers: headers,
      })
      .then((response) => {
        setOffers(response.data.data);
      })
      .catch((error) => {
        setOffers([]);
        console.error("There was an error!", error);
      });
  }, []);

  const SortBy = () => {
    function onChangeValue(event) {
      sortMethodChange(event.target.value);
    }
    return (
      <>
        <p style={{ fontSize: 14, fontWeight: "bold" }}>Sort by</p>
        <div onChange={onChangeValue}>
          <input
            type="radio"
            value="2"
            defaultChecked={selSort == 2}
            name="location"
          />{" "}
          Nearest <br />
          <input
            type="radio"
            value="1"
            defaultChecked={selSort == 1}
            name="location"
          />{" "}
          Top Rated <br />
          <input
            type="radio"
            value="0"
            defaultChecked={selSort == 0}
            name="location"
          />{" "}
          New
        </div>
      </>
    );
  };

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        zIndex: 99,
        background: "white",
      }}
    >
      <div style={{ padding: "0px 20px 10px 20px" }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <img
            src={Back}
            style={{ width: "22px", marginRight: "8px" }}
            alt="logo"
            onClick={() => Close()}
          />
          <h2> Sort By & Filters </h2>
        </div>
        {showAll ? <hr className="solid" /> : null}

        {showAll ? <HeaderSearch img dropDown /> : null}
        {!showAll ? (
          <>
            <div style={{ display: "flex" }}>
              {filterType.map((item, index) => (
                <div
                  key={index}
                  style={{
                    border: "1px solid #000",
                    display: "flex",
                    backgroundColor: index === 0 ? "black" : "white",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      fontSize: 12,
                      fontWeight: "bold",
                      margin: "3px 7px",
                      color: index === 0 ? "white" : "black",
                      padding: "5px 10px",
                    }}
                  >
                    {item}
                  </p>
                </div>
              ))}
            </div>
            <hr className="solid" />
            <SortBy />
            <hr className="solid" />
            <p style={{ fontSize: 14, fontWeight: "bold" }}>Offer type</p>
            <div>
              {offers.map((item, index) => (
                <div key={index} onChange={(w) => HandleChange(w.target.value)}>
                  <input
                    type="radio"
                    value={item.id}
                    name="otype"
                    defaultChecked={selectedOffer == item.id}
                  />{" "}
                  {item.name} <br />
                </div>
              ))}
            </div>
            <FoodAndDrinks setShowAll={setShowAll} />
          </>
        ) : (
          <ShowAll />
        )}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100px",
          }}
        >
          <button
            style={{
              marginTop: "5px",
              textDecoration: "underline",
              color: "black",
              width: "30%",
              backgroundColor: "gray",
              borderRadius: 15,
              padding: "10px",
              border: "none",
            }}
            onClick={() => {
              setShowAll(false);
              HandleChange(1);
              selectedOffer = 1;
              Close();
            }}
          >
            Clear All
          </button>
          <button
            onClick={() => Close()}
            style={{
              marginLeft: 10,
              color: "white",
              width: "30%",
              backgroundColor: "#E44f4b",
              borderRadius: 15,
              padding: "10px",
              border: "none",
            }}
          >
            Done
          </button>
        </div>
      </div>
    </div>
  );
}

const ShowAll = () => {
  const filter = [
    "Chai",
    "Coffe",
    "Salad",
    "Karai",
    "Burger",
    " Zinder Burger",
    "BBQ",
    "Nihari",
    "Lassi",
    "Pasta",
    "Sandwich",
    "Wrap",
    "Dinner",
    "Breakfast",
    "Soup",
    "Drinks",
    "Kabab",
    "Pizza",
    "Cake",
    "Ice-creame",
  ];
  function onChangeValue(event) {
    console.log(event.target.value);
  }
  return (
    <div onChange={onChangeValue}>
      {filter.map((item, index) => (
        <React.Fragment key={index}>
          <input
            style={{ marginTop: 10 }}
            type="radio"
            value={item}
            name={item}
          />
          {item}
          <br />
        </React.Fragment>
      ))}
    </div>
  );
};

const FoodAndDrinks = ({ setShowAll }) => {
  const [open, setOpen] = React.useState(false);
  function onChangeValue(event) {
    console.log(event.target.value);
  }
  return (
    <>
      <div
        style={{ display: "flex", justifyContent: "space-between" }}
        onClick={() => setOpen(!open)}
      >
        <p style={{ fontSize: 14, fontWeight: "bold" }}>Food & Drinks</p>
        <img
          src={open ? ArrowUp : ArrowDown}
          alt=""
          style={{ heiht: "10px", width: "10px" }}
        />
      </div>
      <hr className="solid" />

      {open && (
        <>
          <p style={{ fontSize: 14, fontWeight: "bold" }}>Food Type</p>

          <div onChange={onChangeValue}>
            <input type="radio" value="nearest" name="location" /> Breakfast{" "}
            <br />
            <input
              type="radio"
              value="To-reated"
              name="location"
            /> Chinese <br />
            <input type="radio" value="new" name="location" /> Hi-Tea <br />
            <input
              type="radio"
              value="To-reated"
              name="location"
            /> Biryani <br />
            <input type="radio" value="new" name="location" /> Pizza <br />
            <input type="radio" value="new" name="location" /> Dessert
          </div>
          <p
            onClick={() => setShowAll(true)}
            style={{
              marginTop: "5px",
              textDecoration: "underline",
              color: "blueviolet",
            }}
          >
            Show all
          </p>
        </>
      )}
    </>
  );
};
export default Filters;
