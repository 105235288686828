import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { MotionConfig } from "framer-motion"

const root = ReactDOM.createRoot(document.getElementById('root'));

function getUserAuthCodeResult(jsonResult){ var result=JSON.parse(jsonResult); alert('status:'+result.status); alert('authCode:'+result.authCode); alert('granted scopes:'+result.grantedScopes); }

root.render(
  <MotionConfig transition={{ duration: 1 }}>
    <App />
  </MotionConfig>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
